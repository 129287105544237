import React from 'react';
import { Link } from 'gatsby';

import Modal from '../../Modal';

const text = {
  error: {
    title: 'Error with submission.',
    message:
      "We're sorry, but there was an error with your submission. Please check that all required fields are completed correctly. If you're still having issues, please contact our customer support team. Thank you.",
  },

  success: {
    title: 'Thank you for your interest in our tours!',
    message:
      "We appreciate you taking the time to provide your information and will be in touch with you shortly. If you have any questions or concerns in the meantime, please don't hesitate to contact us.",
  },
};

const FormOnSubmitModal = ({ open, onClose, isSuccess }) => {
  return (
    <Modal open={open} onClose={onClose} sx={{ maxWidth: '1060px' }}>
      <div className="card-form-success">
        <div className="card-form-success-bg"></div>
        <div>
          <h2
            style={{
              maxWidth: '469px',
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            {isSuccess ? text.success.title : text.error.title}
          </h2>

          <p
            style={{
              maxWidth: '546px',
              textAlign: 'center',
              margin: '13px auto 0 auto',
            }}
          >
            {isSuccess ? text.success.message : text.error.message}
          </p>
        </div>
        <div
          style={{
            maxWidth: '345px',
            margin: '40px auto 0 auto',
          }}
        >
          <Link to="/contact" className="btn btn-md btn-primary-ii btn-submit w-full">
            Contact Us
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default FormOnSubmitModal;
