import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import CountryList from 'country-list-with-dial-code-and-flag';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

import Grid from '../../Grid';
import Select from '../../Form/Select';
import FormOnSubmitModal from './FormOnSubmitModal';
import FeaturedImage from '../../../images/tour-form2.jpg';
import { useTourInterest } from '../../../context/tourInterestContext';
import { tourDestinations } from '../../../constants/tourInterestForm';

const AdditionalInformation = ({ goToPreviousView }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState({});
  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
  const { setTourInterest, tourInterest, resetTourInterest } = useTourInterest();

  const onSubmitModalClose = () => {
    setShowSubmitModal(false);

    if (isFormSubmitSuccess) {
      resetTourInterest();

      if (window && window.location) {
        window.location.href = '/';
      }
    }
  };

  const onFormSubmitSuccess = () => {
    setShowSubmitModal(true);
    setIsSubmitting(false);
    setIsFormSubmitSuccess(true);
  };

  const onFormSubmitFail = () => {
    setShowSubmitModal(true);
    setIsSubmitting(false);
    setIsFormSubmitSuccess(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // set form validation errors
    const errors = {};
    if (tourInterest.preferredTours.length === 0) errors.preferredTours = 'Preferred tours is required';
    if (!tourInterest.willPayExtraForSmallerTour) errors.willPayExtraForSmallerTour = 'This field is required';
    if (!tourInterest.willPayExtraForPhotoServices) errors.willPayExtraForPhotoServices = 'This field is required';
    setFormValidationErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const { dialCode } = CountryList.findOneByCountryCode(tourInterest.dialCountryCode);

    setIsSubmitting(true);

    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/tour-participation-interest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...tourInterest,
        phone: `(${dialCode}) ${tourInterest.phone}`,
      }),
    })
      .then((resp) => resp.json())
      .then((body) => {
        if (body.success) {
          onFormSubmitSuccess();
        } else {
          onFormSubmitFail();
        }
      })
      .catch((err) => {
        console.error(err.message);
        onFormSubmitFail();
      });
  };

  const handlePreferredToursCheckbox = (e) => {
    const { preferredTours } = tourInterest;

    // remove item from array if unchecked and add if checked
    if (e.target.checked) {
      preferredTours.push(e.target.value);
    } else {
      const index = preferredTours.indexOf(e.target.value);
      if (index > -1) {
        preferredTours.splice(index, 1);
      }
    }

    setTourInterest({
      ...tourInterest,
      preferredTours,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTourInterest({
      ...tourInterest,
      [name]: value,
    });
  };

  const handleSelectChange = ({ name, value }) => {
    setTourInterest({
      ...tourInterest,
      [name]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col xs="1 / span 2" md="2 / span 4">
            <h4
              style={{
                textAlign: 'left',
              }}
            >
              Additional Information
            </h4>
            <Box
              sx={{
                paddingRight: {
                  xs: '0px',
                  md: '40px',
                },
              }}
            >
              <div className="form-element">
                <label className="label-margin">Locations you would like to visit</label>

                {tourDestinations.map((destination) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginTop: '12px',
                    }}
                    key={destination}
                  >
                    <Checkbox
                      disableRipple
                      icon={<RadioButtonUncheckedRoundedIcon />}
                      checkedIcon={<CheckCircleRoundedIcon />}
                      size="small"
                      sx={{
                        height: '16px',
                        mt: '2px',
                      }}
                      value={destination}
                      onChange={handlePreferredToursCheckbox}
                      id={destination}
                      checked={tourInterest.preferredTours.includes(destination)}
                    />
                    <label
                      style={{
                        fontWeight: '600',
                      }}
                      htmlFor={destination}
                    >
                      {destination}
                    </label>
                  </div>
                ))}
                <label className="form-error">{formValidationErrors.preferredTours}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">
                  Would you pay extra for a smaller more intimate tour with 15-25 people?
                </label>
                <Select
                  value={tourInterest.willPayExtraForSmallerTour}
                  onChange={(value) => handleSelectChange({ name: 'willPayExtraForSmallerTour', value })}
                  button={<div className="w-full text-left">{tourInterest.willPayExtraForSmallerTour}</div>}
                  className="w-full"
                  buttonClass="w-full"
                >
                  {['Yes', 'No'].map((opt) => (
                    <Select.Option value={opt} key={opt}>
                      {opt}
                    </Select.Option>
                  ))}
                </Select>
                <label className="form-error">{formValidationErrors.willPayExtraForSmallerTour}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">
                  Would you be interested in a digital photo album and photo book of your trip taken and compiled by a
                  professional photographer?
                </label>
                <Select
                  value={tourInterest.willPayExtraForPhotoServices}
                  onChange={(value) => handleSelectChange({ name: 'willPayExtraForPhotoServices', value })}
                  button={<div className="w-full text-left">{tourInterest.willPayExtraForPhotoServices}</div>}
                  className="w-full"
                  buttonClass="w-full"
                >
                  {['Yes', 'No'].map((opt) => (
                    <Select.Option value={opt} key={opt}>
                      {opt}
                    </Select.Option>
                  ))}
                </Select>
                <label className="form-error">{formValidationErrors.willPayExtraForPhotoServices}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">Do you have any other special requests or preference? (optional)</label>
                <textarea name="additionalRequests" className="w-full" onChange={handleInputChange} />
              </div>
            </Box>
          </Grid.Col>

          <Grid.Col
            xs="1 / span 2"
            md="auto / span 6"
            sx={{
              order: {
                xs: '-1',
                md: '1',
              },
            }}
          >
            <Box
              sx={{
                paddingTop: {
                  xs: '0px',
                  md: '80px',
                },
              }}
            >
              <LazyLoadImage className="w-full" src={FeaturedImage} />
            </Box>
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col xs="1 / span 2" md="2 / span 4">
            <Grid
              sx={{
                paddingRight: {
                  xs: '0px',
                  md: '40px',
                },
                marginTop: {
                  xs: '0px',
                  md: '37px',
                },
              }}
            >
              <Grid.Col xs="1 / span 2" md="1 / span 6">
                <input
                  type="button"
                  value="Previous"
                  className="btn-md btn-outline w-full"
                  onClick={goToPreviousView}
                />
              </Grid.Col>
              <Grid.Col xs="1 / span 2" md="auto / span 6">
                {isSubmitting ? (
                  <button
                    type="button"
                    className="btn-md btn-outline btn-loading w-full"
                    disabled
                    style={{
                      cursor: 'not-allowed',
                    }}
                  >
                    <span className="spinner"></span> Submitting
                  </button>
                ) : (
                  <button type="submit" className="btn btn-md btn-primary-ii btn-submit w-full">
                    Submit
                  </button>
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </form>

      <FormOnSubmitModal open={showSubmitModal} onClose={onSubmitModalClose} isSuccess={isFormSubmitSuccess} />
    </>
  );
};

export default AdditionalInformation;
