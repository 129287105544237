import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Grid from '../../Grid';
import { tourImageGallery } from '../../../constants/tourInterestForm';

const TourImageGallery = () => {
  return (
    <Grid
      sx={{
        marginTop: {
          xs: '64px',
          md: '80px',
        },
        gap: '0',
      }}
    >
      <Grid.Col xs="1 / span 2" md="2 / span 10">
        <h4>Christian History and Bible Tours</h4>
      </Grid.Col>

      <Grid.Col xs="1 / span 2" md="2 / span 10">
        <Grid
          sx={{
            rowGap: '2rem',
            columnGap: '2.5rem',
          }}
        >
          {tourImageGallery.map((imageGroup, index) => (
            <Grid.Col xs="1 / span 2" md="auto / span 4" key={imageGroup.caption}>
              <LazyLoadImage
                className="w-full"
                src={imageGroup.image}
                style={{
                  objectFit: 'cover',
                  aspectRatio: '4/3',
                  width: '100%',
                }}
                alt={imageGroup.caption}
              />
              <p
                style={{
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  marginTop: '0.6875rem',
                }}
              >
                {imageGroup.caption}
              </p>
            </Grid.Col>
          ))}
        </Grid>
      </Grid.Col>
    </Grid>
  );
};

export default TourImageGallery;
