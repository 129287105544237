import React, { useState } from 'react';
import { Popover, Combobox } from '@headlessui/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CountryList from 'country-list-with-dial-code-and-flag';

import { isBrowser } from '../../../utils/isBrowser';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import SearchIcon from '../../../images/icon-search-faded.svg';

const SelectCountryOption = ({ countryCode }) => {
  if (!isBrowser()) return null; // avoid rendering on server side
  const { dialCode } = CountryList.findOneByCountryCode(countryCode);

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      <span
        className={`fi fi-${countryCode.toLowerCase()}`}
        style={{
          flexShrink: 0,
        }}
      ></span>
      ({dialCode})
    </div>
  );
};

const DialCodeSelector = ({ dialCountryCode, setDialCountryCode }) => {
  const [query, setQuery] = useState('');

  if (!isBrowser()) return null; // avoid rendering on server side

  const filteredCountries =
    query === ''
      ? CountryList.getAll()
      : CountryList.getAll().filter((country) => {
          return country.name.toLowerCase().startsWith(query.toLowerCase());
        });

  return (
    <Popover
      style={{
        position: 'relative',
      }}
    >
      <Popover.Button
        className="select"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '18px',
        }}
      >
        <SelectCountryOption countryCode={dialCountryCode} />
        <ExpandMoreIcon fontSize="small" />
      </Popover.Button>

      <Popover.Panel
        style={{
          position: 'absolute',
          zIndex: 10,
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        }}
      >
        {({ close }) => (
          <Combobox
            value={dialCountryCode}
            onChange={(value) => {
              setDialCountryCode(value);
              close();
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '8px',
                backgroundColor: '#fff',
                width: '273px',
              }}
            >
              <Combobox.Input
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                style={{
                  backgroundImage: `url('${SearchIcon}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '10px center',
                  paddingLeft: '48px',
                  backgroundColor: '#fff',
                  width: '100%',
                }}
                autoFocus
                placeholder="Search"
              />
            </div>
            <Combobox.Options
              static
              style={{
                maxHeight: '18.75rem',
                overflowY: 'scroll',
                backgroundColor: '#FFF',
              }}
            >
              {filteredCountries.map((country) => (
                <Combobox.Option key={country.code} value={country.code}>
                  {({ selected, active }) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'start',
                        padding: '12px 16px',
                        backgroundColor: `${selected ? '#FECB04' : '#FFF'}`,
                        cursor: 'pointer',
                      }}
                    >
                      <span
                        className={`fi fi-${country.code.toLowerCase()}`}
                        style={{
                          flexShrink: 0,
                          marginRight: '8px',
                          paddingTop: '4px',
                        }}
                      ></span>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '64px',
                          flexShrink: 0,
                          color: `${!selected && '#ADADAD'}`,
                        }}
                      >
                        ({country.dial_code})
                      </span>
                      <span>{country.name}</span>
                    </div>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default DialCodeSelector;
