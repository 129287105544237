import validator from 'validator';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Grid from '../../Grid';
import Select from '../../Form/Select';
import AddMemberModal from './AddMemberModal';
import DialCodeSelector from './DialCodeSelector';
import FeaturedImage from '../../../images/tour-form1.jpg';
import AdditionalMembersTable from './AdditionalMembersTable';
import { useTourInterest } from '../../../context/tourInterestContext';
import { participantAgeRange } from '../../../constants/tourInterestForm';

import '/node_modules/flag-icons/css/flag-icons.min.css';

const TravellerInformation = ({ goToNextView }) => {
  const [showAdditionalMember, setShowAdditionalMember] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState({});

  const { setTourInterest, tourInterest, addMember, removeMembers } = useTourInterest();

  const handleSubmit = (event) => {
    event.preventDefault();

    // set form validation errors
    const errors = {};
    if (!tourInterest.name) errors.name = 'Name is required';
    if (!validator.isEmail(tourInterest.email)) errors.email = 'Email is invalid';
    if (!tourInterest.country) errors.country = 'Country is required';
    if (!tourInterest.phone) errors.phone = 'Phone number is required';
    if (!tourInterest.dialCountryCode) errors.dialCountryCode = 'Dial code is required';
    if (!tourInterest.ageRange) errors.ageRange = 'Age range is required';
    setFormValidationErrors(errors);

    if (Object.keys(errors).length > 0) return;
    goToNextView();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTourInterest({
      ...tourInterest,
      [name]: value,
    });
  };

  const handleSelectChange = ({ name, value }) => {
    setTourInterest({
      ...tourInterest,
      [name]: value,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Col xs="1 / span 2" md="2 / span 4">
            <h4
              style={{
                textAlign: 'left',
              }}
            >
              Traveller Information
            </h4>
            <Box
              sx={{
                paddingRight: {
                  xs: '0px',
                  md: '40px',
                },
              }}
            >
              <div className="form-element">
                <label className="label-margin">Full Name</label>
                <input
                  type="text"
                  name="name"
                  className="w-full"
                  value={tourInterest.name}
                  onChange={handleInputChange}
                />
                <label className="form-error">{formValidationErrors.name}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">Email address</label>
                <input
                  type="email"
                  name="email"
                  className="w-full"
                  value={tourInterest.email}
                  onChange={handleInputChange}
                />
                <label className="form-error">{formValidationErrors.email}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">Country</label>
                <input
                  type="country"
                  name="country"
                  className="w-full"
                  value={tourInterest.country}
                  onChange={handleInputChange}
                />
                <label className="form-error">{formValidationErrors.country}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">Phone</label>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                  }}
                >
                  <DialCodeSelector
                    dialCountryCode={tourInterest.dialCountryCode}
                    setDialCountryCode={(value) => handleSelectChange({ name: 'dialCountryCode', value })}
                  />
                  <div
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="w-full"
                      value={tourInterest.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <label className="form-error">{formValidationErrors.dialCountryCode}</label>
                <label className="form-error">{formValidationErrors.phone}</label>
              </div>

              <div className="form-element">
                <label className="label-margin">Age Range</label>
                <Select
                  value={tourInterest.ageRange}
                  onChange={(value) => handleSelectChange({ name: 'ageRange', value })}
                  button={<div className="w-full text-left">{tourInterest.ageRange}</div>}
                  className="w-full"
                  buttonClass="w-full"
                >
                  {participantAgeRange.map((ageRange) => (
                    <Select.Option value={ageRange} key={ageRange}>
                      {ageRange}
                    </Select.Option>
                  ))}
                </Select>
                <label className="form-error">{formValidationErrors.ageRange}</label>
              </div>
            </Box>
          </Grid.Col>

          <Grid.Col
            xs="1 / span 2"
            md="auto / span 6"
            sx={{
              order: {
                xs: '-1',
                md: '1',
              },
            }}
          >
            <Box
              sx={{
                paddingTop: {
                  xs: '0px',
                  md: '80px',
                },
              }}
            >
              <LazyLoadImage className="w-full" src={FeaturedImage} />
            </Box>
          </Grid.Col>
        </Grid>

        <AdditionalMembersTable removeMembers={removeMembers} additionalMembers={tourInterest?.additionalMembers} />

        <Grid>
          <Grid.Col xs="1 / span 2" md="2 / span 4">
            <Box
              sx={{
                paddingRight: {
                  xs: '0px',
                  md: '40px',
                },
              }}
            >
              <input
                type="button"
                value="Add Additional Member"
                className="btn-md btn-outline w-full"
                onClick={() => setShowAdditionalMember(true)}
              />
            </Box>
          </Grid.Col>
        </Grid>

        <Box
          sx={{
            display: 'block',
            maxWidth: {
              xs: '100%',
              md: '282px',
            },
            margin: {
              xs: '26px auto 0 auto',
              md: '56px auto 0 auto',
            },
          }}
        >
          <input type="submit" value="Next" className="btn btn-md btn-primary-ii btn-submit w-full" />
        </Box>
      </form>
      <AddMemberModal
        addMember={addMember}
        open={showAdditionalMember}
        onClose={() => setShowAdditionalMember(false)}
      />
    </>
  );
};

export default TravellerInformation;
