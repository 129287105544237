import { graphql } from 'gatsby';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import { scrollToTop } from '../utils/scrollToTop';
import { TourInterestProvider } from '../context/tourInterestContext';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import TourImageGallery from '../components/Tour/InterestForm/TourImageGallery';
import TravellerInformation from '../components/Tour/InterestForm/TravellerInformation';
import AdditionalInformation from '../components/Tour/InterestForm/AdditionalInformation';

const ViewsEnum = {
  TRAVELLER_INFORMATION: 'travellerInformation',
  ADDITIONAL_INFORMATION: 'additionalInformation',
};

const Tours = (props) => {
  const {
    data: { allPrismicTourForm },
  } = props;

  // We are not using this data for now as rich text data is returned as 'null' and slices as empty array because of some issue with the Prismic
  const allPrismicData = allPrismicTourForm?.nodes[0]?.data;

  const [currentView, setCurrentView] = useState(ViewsEnum.TRAVELLER_INFORMATION);

  const goToNextView = () => {
    setCurrentView(ViewsEnum.ADDITIONAL_INFORMATION);
    scrollToTop();
  };

  const goToPreviousView = () => {
    setCurrentView(ViewsEnum.TRAVELLER_INFORMATION);
    scrollToTop();
  };

  return (
    <TourInterestProvider>
      <BodyClassName className="body-dark">
        <DefaultLayoutComponent title="Tours">
          <div className="site-main">
            <div className="wrapper wrapper-xl pd-x-md">
              <Box
                className="bg-light tour-interest-form"
                sx={{
                  padding: {
                    xs: '24px 15px',
                    md: '73px 0 112px 0',
                  },
                  color: '#0A0A0A',
                }}
              >
                <h3 className="sectionHeading">Tour Interest Form</h3>

                {currentView === ViewsEnum.TRAVELLER_INFORMATION ? (
                  <TravellerInformation goToNextView={goToNextView} />
                ) : (
                  <AdditionalInformation goToPreviousView={goToPreviousView} />
                )}

                <TourImageGallery />
              </Box>
            </div>
          </div>
        </DefaultLayoutComponent>
      </BodyClassName>
    </TourInterestProvider>
  );
};

export const query = graphql`
  {
    allPrismicTourForm {
      nodes {
        data {
          title {
            text
          }
          age_range {
            age_range_item
          }
          locations {
            location_name
          }
          image_gallery_title {
            text
          }
          form_image_1 {
            url
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
          form_image_2 {
            url
            alt
            fluid(maxWidth: 1200) {
              ...GatsbyPrismicImageFluid
            }
          }
          body {
            ... on PrismicTourFormBodyImageGalleryItem {
              primary {
                image {
                  url
                  alt
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                caption
              }
            }
          }
        }
      }
    }
  }
`;

export default Tours;
