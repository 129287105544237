import React, { useState, useContext, createContext } from 'react';

const INITIAL_STATE = {
  name: '',
  email: '',
  country: '',
  dialCountryCode: 'US',
  phone: '',
  ageRange: '',
  willPayExtraForSmallerTour: '',
  willPayExtraForPhotoServices: '',
  preferredTours: [],
  additionalRequests: '',
  additionalMembers: [],
};

const TourInterestContext = createContext();

export const TourInterestProvider = ({ children }) => {
  const [tourInterest, setTourInterest] = useState(INITIAL_STATE);

  const clearAllMembers = () => setTourInterest({ ...tourInterest, additionalMembers: [] });

  const addMember = (member) =>
    setTourInterest({ ...tourInterest, additionalMembers: [...tourInterest.additionalMembers, member] });

  const removeMember = (index) => {
    const newMembers = [...tourInterest.additionalMembers];
    newMembers.splice(index, 1);
    setTourInterest({ ...tourInterest, additionalMembers: newMembers });
  };

  const removeMembers = (membersIndex) => {
    const newMembers = [...tourInterest.additionalMembers];
    membersIndex.forEach((index) => newMembers.splice(index, 1));
    setTourInterest({ ...tourInterest, additionalMembers: newMembers });
  };

  const resetTourInterest = () =>
    setTourInterest({
      ...INITIAL_STATE,
      preferredTours: [],
    });

  return (
    <TourInterestContext.Provider
      value={{
        tourInterest,
        setTourInterest,
        clearAllMembers,
        addMember,
        removeMember,
        removeMembers,
        resetTourInterest,
      }}
    >
      {children}
    </TourInterestContext.Provider>
  );
};

export const useTourInterest = () => useContext(TourInterestContext);
